import axios from "axios";
import { AuthService } from '../Services/AuthService';

const authService = new AuthService();
const API_BASE_URL: string | undefined = process.env.REACT_APP_BASE_API_URL;

  export async function sendAuthorization(): Promise<boolean> {
    try {
      // Retrieve the user from authService
      const user = await authService.getUser();

      // Retrieve the UUID from sessionStorage
      const uuid = sessionStorage.getItem('uuid');
      const nationalid = sessionStorage.getItem('nationalid');
      let url = `${API_BASE_URL}/auth`;
      if (uuid) {
        const params = new URLSearchParams({ uuid });
        url += `?${params.toString()}`;
      }
      if (user?.profile?.name) {
        const params = new URLSearchParams({ name: user.profile.name });
        url += `&${params.toString()}`;
      }
      if (nationalid) {
        const params = new URLSearchParams({ nationalid });
        url += `&${params.toString()}`;
      }
      
      // Send the GET request with the Authorization header.
      const response = await axios.get(url, {
        headers: {
          Authorization: authService.getAuthorizationHeaderValue(user),
        },
      });
  
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      throw error;
    }
  }