import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './Components/Login';
import SignInOidc from './Components/SignInOidc';
import './App.css';

const App: React.FC = () => {
  return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signin-oidc" element={<SignInOidc />} />
      </Routes>
  );
}

export default App;
