import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthService } from '../Services/AuthService';

const authService = new AuthService();

function Login() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const uuid = params.get('uuid');
    const nationalid = params.get('nationalid');
    if (uuid && nationalid) {
      sessionStorage.setItem('uuid', uuid);
      sessionStorage.setItem('nationalid', nationalid);
    }

    authService.login().then(() => {
      // Login successful
    });
  }, [location.search]);

  return <div></div>;
};

export default Login;
