import { useEffect, useState, useMemo } from 'react';
import { AuthService } from '../Services/AuthService';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { sendAuthorization } from '../Services/APIService';
import Link from '@mui/material/Link';
import React from 'react';

const SignInOidc = () => {
  const authService = useMemo(() => new AuthService(), []);
  const [loading, setLoading] = useState(true);
  const [authStatus, setAuthStatus] = useState<{ isError: boolean; message: React.ReactNode } | null>(null);

  const errorMessage = (errorMessageText: string) => (
    <React.Fragment>
      {errorMessageText}
      {' '}
      Endurnýja þarf umboð fyrir Vorkerfi þar sem eldri umboð eru hætt að virka. Skoðaðu upplýsingar í{' '}
      <Link href="https://island.is/s/fiskistofa/frett/mikilvaegt-ad-endurnyja-umbod-i-vor-kerfi" target="_blank" rel="noopener">
        þessari frétt
      </Link>.
    </React.Fragment>
  );

  useEffect(() => {
    const handleLoginChange = async () => {
      setLoading(true);
      try {
        await authService.completeAuthentication();
        if (authService.isLoggedIn()) {
          try {
            await sendAuthorization();
            setAuthStatus({ isError: false, message: "Þú ert skráður inn! Þú mátt loka þessum glugga." });
          } catch (error: any) {
            if (error.response && error.response.status === 409) {
              setAuthStatus({ isError: true, message: errorMessage(error.response.data) }); 
            } else if (
              error.response.data.includes("hefur ekki réttindi til að veita aðgang að Vorkerfi")
            ) {
              // Replace the original part of the message
              const sanitizedError = error.response.data.replace(
                "hefur ekki réttindi til að veita aðgang að Vorkerfi",
                "hefur ekki aðgang að Vorkerfi."
              ).split("[")[0];

              setAuthStatus({ isError: true, message: errorMessage(sanitizedError) });
            } else {
              setAuthStatus({ isError: true, message: errorMessage("Innskráning mistókst - vinsamlegast reynið aftur síðar.") });
            }
          }
        } else {
          throw new Error("Not logged in");
        }
      } catch (err) {
        setAuthStatus({ isError: true, message: errorMessage("Innskráning mistókst - vinsamlegast reynið aftur síðar.") });
      } finally {
        authService.logoutSilent();
        setLoading(false);
      }
    };
    handleLoginChange();
  }, [authService]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f4f4f4"
      gap={2}
    >
      {loading ? (
        <>
          <p>Skrái þig inn...</p>
          <CircularProgress />
        </>
      ) : authStatus ? (
        <Alert severity={authStatus.isError ? "error" : "success"} sx={{ width: '50%', mt: 2 }}>
          {authStatus.message}
        </Alert>
      ) : null}
    </Box>
  );
};

export default SignInOidc;
